import * as Qs from "qs";
import DfProperty from "@/vue/domain/content/df-property";
import VueRouter, { RouterMode } from "vue-router";
import DfStore from "@/vue/domain/store/df-store";
import CryptoJS from "crypto-js";
import Store from "@/vue/infrastructure/store/store";

class Utils {
  static readonly APP_NAME: string = "SMT Digitalflyer";
  static readonly BEARER_ACCESS_TOKEN_KEY: string =
    "df_digital_flyer_bearer_access_token";
  static readonly BEARER_REFRESH_TOKEN_KEY: string =
    "df_digital_flyer_bearer_refresh_token";
  static readonly APP_VERSION: string = "APP_VERSION";

  static readonly ISO_8601_STRING_PATTERN: string = "YYYYMMDDHHmmss";
  static readonly DATE_STRING_PATTER: string = "DD/MM/YYYY";
  static readonly DATE_STRING_SHORT_PATTER: string = "DD/MM";
  static readonly DATE_STRING_HOURS_MINUTES: string = "HH:mm";
  static readonly DATE_STRING_HOURS_MINUTES_SECONDS: string = "HH:mm";

  static readonly PROPERTY_BACKGROUND_MOBILE: string = "BACKGROUND_MOBILE";

  static readonly PROPERTY_BACKGROUND_IMAGE: string = "BACKGROUND_IMAGE";
  static readonly PROPERTY_SHOW_LABEL_MOBILE: string = "SHOW_LABEL_MOBILE";
  static readonly PROPERTY_BACKGROUND_IMAGE_PAGE_HEADER: string =
    "BACKGROUND_IMAGE_PAGE_HEADER";
  static readonly PROPERTY_BACKGROUND_IMAGE_PAGE_HEADER_SIZE_CONTAIN: string =
    "BACKGROUND_IMAGE_PAGE_HEADER_SIZE_CONTAIN";
  static readonly PROPERTY_BACKGROUND_COLOR: string = "BACKGROUND_COLOR";
  static readonly PROPERTY_POSITION: string = "POSITION";
  static readonly PROPERTY_MAIN_SITE: string = "MAIN_SITE";

  static readonly PROPERTY_LOGO: string = "LOGO";
  static readonly PROPERTY_EXTRA_LOGO: string = "EXTRA_LOGO";
  static readonly PROPERTY_SELEX_LOGO_HEADER: string = "SELEX_LOGO_HEADER";
  static readonly PROPERTY_SELEX_HOME_URL: string = "SELEX_HOME_URL";
  static readonly PROPERTY_SELEX_STORES_URL: string = "SELEX_STORES_URL";
  static readonly PROPERTY_SELEX_STORE_URL: string = "SELEX_STORE_URL";
  static readonly PROPERTY_SELEX_AWARDS_URL: string = "SELEX_AWARDS_URL";
  static readonly PROPERTY_SELEX_STORE_ALIAS: string = "SELEX_STORE_ALIAS";
  static readonly PROPERTY_SELEX_PRIVATE_AREA_URL: string =
    "SELEX_PRIVATE_AREA_URL";
  static readonly PROPERTY_SELEX_FOOTER: string = "SELEX_FOOTER";
  static readonly PROPERTY_SELEX_HEADER_COLOR: string = "SELEX_HEADER_COLOR";
  static readonly PROPERTY_SELEX_MALLS_URL: string = "SELEX_MALLS_URL";

  static readonly PROPERTY_LOGO_FOOTER: string = "LOGO_FOOTER";
  static readonly PROPERTY_LOGO_HEADER: string = "LOGO_HEADER";
  static readonly PROPERTY_LOGO_URL: string = "LOGO_URL";
  static readonly PROPERTY_HEADER_MOBILE: string = "HEADER_MOBILE";
  static readonly PROPERTY_BACKGROUND_POSITION: string = "BACKGROUND_POSITION";
  static readonly PROPERTY_CAROUSEL_AUTOPLAY: string = "CAROUSEL_AUTOPLAY";
  static readonly PROPERTY_PREVIEW: string = "PREVIEW";
  static readonly PROPERTY_COVER: string = "COVER";
  static readonly PROPERTY_GALLERY: string = "IMAGES";
  static readonly PROPERTY_DATE: string = "DATE";
  static readonly PROPERTY_TITLE: string = "TITLE";
  static readonly PROPERTY_TEXT: string = "TEXT";
  static readonly PROPERTY_BODY: string = "BODY";
  static readonly PROPERTY_HEADER: string = "HEADER";
  static readonly PROPERTY_PDF: string = "PDF";
  static readonly PROPERTY_URL: string = "URL";
  static readonly PROPERTY_URL_PUBLIC: string = "URL_PUBLIC";
  static readonly PROPERTY_ATTACHMENTS: string = "ATTACHMENTS";
  static readonly PROPERTY_DIFFICULTY_LEVEL: string = "DIFFICULTY_LEVEL";
  static readonly PROPERTY_PREPARATION_TIME: string = "PREPARATION-TIME";
  static readonly PROPERTY_COOKING_TIME: string = "COOKING-TIME";
  static readonly PROPERTY_SERVINGS: string = "SERVINGS";
  static readonly PROPERTY_INGREDIENTS: string = "INGREDIENTS";
  static readonly PROPERTY_ISSUU_PUBLISHED_URL: string = "ISSUU-PUBLISHED-URL";
  static readonly PROPERTY_FOOTER: string = "FOOTER";
  static readonly PROPERTY_LINK: string = "INTERNAL_LINK";
  static readonly PROPERTY_CONTENT_HTML: string = "HTML_CONTENT";
  static readonly PROPERTY_CONTENT_VIDEO: string = "VIDEO_CONTENT";
  static readonly PROPERTY_CONTENT_VIDEO_CUSTOM: string =
    "VIDEO_CUSTOM_CONTENT";
  static readonly PROPERTY_CONTENT_IMAGE: string = "IMAGE_CONTENT";
  static readonly PROPERTY_CONTENT_PRIMARY: string = "PRIMARY";
  static readonly PROPERTY_CONTENT_AMOUNT: string = "AMOUNT";
  static readonly PROPERTY_CONTENT_DATE: string = "DATE";
  static readonly PROPERTY_ENABLE_MULTI_TAG_FILTER: string =
    "ENABLE_MULTI_TAG_FILTER";
  static readonly PROPERTY_HEADER_TEXT_COLOR: string = "HEADER_TEXT_COLOR";

  static readonly PROPERTY_SENDY_TITLE: string = "SENDY_TITLE";
  static readonly PROPERTY_SENDY_TEXT: string = "SENDY_TEXT";
  static readonly PROPERTY_SENDY_PRIVACY_TEXT: string = "SENDY_PRIVACY_TEXT";
  static readonly PROPERTY_SENDY_LIST_ID: string = "SENDY_LIST_ID";
  static readonly PROPERTY_SENDY_LASTNAME_FIELD: string =
    "SENDY_LASTNAME_FIELD";
  static readonly PROPERTY_SENDY_STORE_FIELD: string = "SENDY_STORE_FIELD";

  static readonly PROPERTY_STORE_CUSTOMER_CARE_URL: string =
    "CUSTOMER_CARE_URL";
  static readonly PROPERTY_STORE_CUSTOMER_CARE_LABEL: string =
    "CUSTOMER_CARE_LABEL";
  static readonly PROPERTY_PRIVATE_AREA_DISABLED: string =
    "PRIVATE_AREA_DISABLED";
  static readonly PROPERTY_PRIVATE_CASHBACK_MONEY_BALANCE: string =
    "MONEY_BALANCE";
  static readonly PROPERTY_PRIVATE_CASHBACK_LAST_UPDATE: string =
    "LAST_UPDATE";

  static readonly PROPERTY_PRODUCT_PREVIEW: string = "IMAGES";
  static readonly PROPERTY_PRODUCT_FINAL_PRICE_FOR_UNIT: string =
    "END-KG-LT-PRICE";
  static readonly PROPERTY_PRODUCT_UNIT: string = "MEASURE-UNIT";
  static readonly PROPERTY_PRODUCT_EAN: string = "EAN";
  static readonly PROPERTY_PRODUCT_DISCOUNT: string = "DISCOUNT";
  static readonly PROPERTY_PRODUCT_FEATURED: string = "FEATURED";
  static readonly PROPERTY_PRODUCT_DISCOUNT_PERCENTUAL: string =
    "DISCOUNT-RATE";
  static readonly PROPERTY_PRODUCT_INITIAL_PRICE: string = "INITIAL-PRICE";
  static readonly PROPERTY_PRODUCT_FINAL_PRICE: string = "END-PRICE";
  static readonly PROPERTY_PRODUCT_DATE_FROM: string = "START-DATE-VALIDITY";
  static readonly PROPERTY_PRODUCT_DATE_TO: string = "END-DATE-VALIDITY";
  static readonly PROPERTY_PRODUCT_INGREDIENTS: string = "INGREDIENTS";
  static readonly PROPERTY_PRODUCT_NUTRITIONAL_VALUES: string =
    "NUTRITIONAL-VALUES";
  static readonly PROPERTY_PRODUCT_NUTRITIONAL_VALUES_CALCULATED: string =
    "CALCULATED-NUTRITION";
  static readonly PROPERTY_PRODUCT_POINTS: string = "POINTS";
  static readonly PROPERTY_PRODUCT_CONTRIBUTION_POINTS: string =
    "CONTRIBUTION-POINTS";
  static readonly PROPERTY_PRODUCT_CONTRIBUTION_PRICE: string =
    "CONTRIBUTION-PRICE";
  static readonly PROPERTY_PRODUCT_BRAND_LOGOS: string = "BRAND-LOGOS";
  static readonly PROPERTY_PRODUCT_PROPERTY_LOGOS: string = "PROPERTY-LOGOS";
  static readonly PROPERTY_PRODUCT_THEME: string = "THEME";
  static readonly PROPERTY_PRODUCT_PROPERTY_MARK: string = "MARK";
  static readonly PROPERTY_PRODUCT_URL_ECOMMERCE: string = "URL-ECOMMERCE";
  static readonly PROPERTY_PRODUCT_URL_VIDEO: string = "URL-VIDEO";
  static readonly PROPERTY_PRODUCT_URL_RECIPE: string = "RECIPE-URL";
  static readonly PROPERTY_PRODUCT_URL: string = "URL";
  static readonly PROPERTY_PRODUCT_CODE: string = "CODE";
  static readonly PROPERTY_PRODUCT_PIECES: string = "PIECES";
  static readonly PROPERTY_PRODUCT_NOTES: string = "NOTES";
  static readonly PROPERTY_PRODUCT_DISCOUNT_VALUE: string = "DISCOUNT-VALUE";
  static readonly PROPERTY_PRODUCT_CAPTION: string = "CAPTION";
  static readonly PROPERTY_PRODUCT_CAPTION_COLOR: string = "CAPTION-COLOR";
  static readonly PROPERTY_PRODUCT_ADDITIONAL_CAPTION: string = "ADDITIONAL_CAPTION";
  static readonly PROPERTY_PRODUCT_QUANTITY: string = "QUANTITY";
  static readonly PROPERTY_PRODUCT_PROPERTIES_FOR_SEARCH: string =
    "PRODUCT_PROPERTIES_FOR_SEARCH";
  static readonly PROPERTY_ECOMMERCE_ENABLED: string =
    "SELEX-ECOMMERCE-ENABLED";
  static readonly PROPERTY_PRODUCT_URL_ECOMMERCE_SELEX: string =
    "SELEX-URL-PRODUCT";
  static readonly PROPERTY_PRODUCT_DIMENSION: string = "DIMENSION";
  static readonly PROPERTY_PRODUCT_CATEGORY: string = "CATEGORY";
  static readonly PROPERTY_PRODUCT_DISCOUNT_ONLY: string = "DISCOUNT-ONLY";
  static readonly PROPERTY_PRODUCT_COUPON: string = "COUPON";
  static readonly PROPERTY_PRODUCT_DESCRIPTION: string = "DESCRIPTION";
  static readonly PROPERTY_PRODUCT_BRAND: string = 'BRAND';
  static readonly PROPERTY_PRODUCT_LONG_DESCRIPTION: string =
    "LONG_DESCRIPTION";
  static readonly PROPERTY_PRODUCT_LOAN_TRANCHE: string = "LOAN-TRANCHE";
  static readonly PROPERTY_PRODUCT_TRANCHE_COUNT: string = "TRANCHE_COUNT";
  static readonly PROPERTY_PRODUCT_TRANCHE_NOTE: string = "TRANCHE_NOTE";
  static readonly PROPERTY_PRODUCT_SPONSOR: string = "SPONSOR";
  static readonly PROPERTY_PRODUCTS_VIEW_MODE: string = "PRODUCTS_VIEW_MODE";
  static readonly PROPERTY_PRODUCTS_DEFAULT_VIEW_MODE: string =
    "PRODUCTS_DEFAULT_VIEW_MODE";
  static readonly PROPERTY_PRODUCTS_ADDITIONAL_IMAGES: string = "ADDITIONAL_IMAGES";

  static readonly PROPERTY_BUTTON_LABEL: string = "LABEL";
  static readonly PROPERTY_BUTTON_ICON_CLASS: string = "ICON_CLASS";
  static readonly PROPERTY_BUTTON_ICON_IMAGE: string = "ICON_IMAGE";
  static readonly PROPERTY_BUTTON_BACKGROUND_COLOR: string = "BACKGROUND_COLOR";
  static readonly PROPERTY_BUTTON_COLOR: string = "COLOR";
  static readonly PROPERTY_BUTTON_VISIBILITY: string = "VISIBILITY";
  static readonly PROPERTY_BUTTON_AUTHENTICATED: string = "AUTHENTICATED";

  static readonly PROPERTY_CARD_CLUB_MY_AREA_LABEL: string = "MY_AREA_LABEL";
  static readonly PROPERTY_CARD_CLUB_MY_AREA_URL: string = "MY_AREA_URL";
  static readonly PROPERTY_CARD_CLUB_MY_AREA_BACKGROUND_IMAGE: string =
    "MY_AREA_BACKGROUND_IMAGE";
  static readonly PROPERTY_CASHBACK_MY_AREA_BACKGROUND_IMAGE: string =
    "MY_AREA_CASHBACK_IMAGE";
  static readonly PROPERTY_CASHBACK_MY_AREA_FONT_COLOR: string =
    "MY_AREA_CASHBACK_FONT_COLOR";

  static readonly PROPERTY_FORM_FIELD: string = "FORM_FIELD_";

  static readonly PROPERTY_CAMPAIGNS_PER_PAGE: string = "CAMPAIGNS_PER_PAGE";

  static readonly PROPERTY_DETAILS_BUTTON: string = "DETAILS-BUTTON";
  static readonly GOOGLE_MAP_URL: string = "https://www.google.com/maps/dir//";

  static readonly LOCAL_STORAGE_CLIENT_NOTIFICATION: string =
    "client-notification-actived";
  static readonly LOCAL_STORAGE_GEO_LOCALIZATION: string =
    "geolocation-actived";

  static readonly PROPERTY_BANNER_POSITION_PRODUCTS_LIST: string =
    "PRODUCTS_LIST";
  static readonly PROPERTY_BRAND_CUSTOMIZED_NEWS_TITLE: string =
    "CUSTOMIZED_NEWS_TITLE";
  static readonly PROPERTY_BRAND_URL_STORE_LOCATOR: string =
    "URL_STORE_LOCATOR";
  static readonly PROPERTY_BRAND_SHOW_PRODUCTS_COUNTER: string =
    "SHOW_PRODUCTS_COUNTER";
  static readonly PROPERTY_BRAND_FORCE_UPPERCASE_PRODUCT_DESCRIPTION: string =
    "FORCE_UPPERCASE_PRODUCT_DESCRIPTION";
  static readonly PROPERTY_BRAND_NAME: string = "BRAND_NAME";
  static readonly PROPERTY_BRAND_REGISTRATION_PRIVACY_TEXT: string =
    "REGISTRATION_PRIVACY_TEXT";
  static readonly PROPERTY_BRAND_REGISTRATION_CLUB_CARD_TEXT: string =
    "REGISTRATION_CLUB_CARD_TEXT";
  static readonly PROPERTY_BRAND_AUTHENTICATED_NEWSLETTER_PAGE: string =
    "authenticated-newsletter";

  static readonly PROPERTY_PROMOTION_POPUP_IMAGE: string = "IMAGES";
  static readonly PROPERTY_PROMOTION_POPUP_START_DATE: string = "START_DATE";
  static readonly PROPERTY_PROMOTION_POPUP_END_DATE: string = "END_DATE";
  static readonly PROPERTY_PROMOTION_POPUP_URL: string = "URL";
  static readonly PROPERTY_PROMOTION_POPUP_SHOW_ALWAYS: string = "SHOW_ALWAYS";
  static readonly PROPERTY_PROMOTION_TYPE: string = "TYPE";

  static readonly PROPERTY_USER_CAMI_CARD_NUMBER: string = "CAMI_CARD_NUMBER";
  static readonly PROPERTY_USER_PROVINCE: string = "PROVINCE";

  static readonly PROFILE_MODE_DEVELOPMENT: string = "development";

  static readonly PRODUCTS_LIST_VIEW: string = "LIST_VIEW";
  static readonly PRODUCTS_FLYER_VIEW: string = "FLYER_VIEW";
  static readonly PRODUCTS_ALL_VIEWS: string = "ALL_VIEWS";

  static readonly TAG_SORT_UNICOMM: Array<string> = [
    "CARTA CLUB",
    "BANCO SERVITO",
    "CARNE",
    "PESCE",
    "ORTOFRUTTA",
    "FRESCO",
    "SURGELATI",
    "SCATOLAME",
    "BEVANDE",
    "CURA CASA",
    "CURA PERSONA",
    "AMICI ANIMALI",
    "ARIA APERTA",
    "FERRAMENTA",
    "GIOCATTOLO",
    "CANCELLERIA",
    "CASALINGO",
    "TESSILE DONNA",
    "TESSILE UOMO",
    "TESSILE BAMBINO",
    "CALZATURE",
    "ACCESSORI BIMBI",
    "TESSILE CASA",
    "ADDOBBO NATALE",
    "VALIGERIA",
    "HI-FI",
    "TECNOLOGIA",
    "ELETTRODOMESTICO",
  ];
  static readonly customComponentsConfig: any = {
    "df-header": {
      default: import(
        /* webpackChunkName: 'df-header' */ "@/vue/components/df-header/df-header.vue"
      ),
      selexselex: import(
        /* webpackChunkName: 'df-header' */ "@/vue/components/overrides/selexselex/df-header/df-header.vue"
      ),
    },
    "df-footer": {
      default: import(
        /* webpackChunkName: 'df-footer' */ "@/vue/components/df-footer/df-footer.vue"
      ),
      selexselex: import(
        /* webpackChunkName: 'df-header' */ "@/vue/components/overrides/selexselex/df-footer/df-footer.vue"
      ),
    },
    "df-section-promotions": {
      default: import(
        /* webpackChunkName: 'df-section-promotions' */ "@/vue/components/df-section-promotions/df-section-promotions.vue"
      ),
    },
    "df-section-links": {
      default: import(
        /* webpackChunkName: 'df-section-links' */ "@/vue/components/df-section-links/df-section-links.vue"
      ),
    },
    "df-section-news": {
      default: import(
        /* webpackChunkName: 'df-section-news' */ "@/vue/components/df-section-news/df-section-news.vue"
      ),
    },
    "df-store-card": {
      default: import(
        /* webpackChunkName: 'df-store-card' */ "@/vue/components/df-store-card/df-store-card.vue"
      ),
    },
    "df-section-banner": {
      default: import(
        /* webpackChunkName: 'df-section-banner' */ "@/vue/components/df-section-banner/df-section-banner.vue"
      ),
    },
    "df-app-navigation": {
      default: import(
        /* webpackChunkName: 'df-app-navigation' */ "@/vue/components/df-app-navigation/df-app-navigation.vue"
      ),
      selexselex: import(
        /* webpackChunkName: 'df-app-navigation' */ "@/vue/components/overrides/selexselex/df-app-navigation/df-app-navigation.vue"
      ),
      euronicsbutali: import(
        /* webpackChunkName: 'df-app-navigation' */ "@/vue/components/overrides/euronicsbutali/df-app-navigation/df-app-navigation.vue"
      ),
      euronicsnova: import(
        /* webpackChunkName: 'df-app-navigation' */ "@/vue/components/overrides/euronicsnova/df-app-navigation/df-app-navigation.vue"
      ),
    },
    "df-section-products": {
      default: import(
        /* webpackChunkName: 'df-section-products' */ "@/vue/components/df-section-products/df-section-products.vue"
      ),
    },
    "df-section-products-filter": {
      default: import(
        /* webpackChunkName: 'df-section-products-filter' */ "@/vue/components/df-section-products-filter/df-section-products-filter.vue"
      ),
    },
    "df-section-feautured": {
      default: import(
        /* webpackChunkName: 'df-section-feautured' */ "@/vue/components/df-section-feautured/df-section-feautured.vue"
      ),
    },
    "df-section-special": {
      default: import(
        /* webpackChunkName: 'df-section-special' */ "@/vue/components/df-section-special/df-section-special.vue"
      ),
    },
    "df-promotion-card": {
      default: import(
        /* webpackChunkName: 'df-promotion-card' */ "@/vue/components/df-promotion-card/df-promotion-card.vue"
      ),
    },
    "df-news-card": {
      default: import(
        /* webpackChunkName: 'df-news-card' */ "@/vue/components/df-news-card/df-news-card.vue"
      ),
    },
    "df-card-product": {
      default: import(
        /* webpackChunkName: 'df-card-product' */ "@/vue/components/df-card-product/df-card-product.vue"
      ),
      unicommemi: import(
        /* webpackChunkName: 'df-card-product' */ "@/vue/components/overrides/unicommemi/df-card-product/df-card-product.vue"
      ),
      unicommfamila: import(
        /* webpackChunkName: 'df-card-product' */ "@/vue/components/overrides/unicommfamila/df-card-product/df-card-product.vue"
      ),
      unicommemisfero: import(
        /* webpackChunkName: 'df-card-product' */ "@/vue/components/overrides/unicommemisfero/df-card-product/df-card-product.vue"
      ),
      unicommmega: import(
        /* webpackChunkName: 'df-card-product' */ "@/vue/components/overrides/unicommmega/df-card-product/df-card-product.vue"
      ),
      superemmepan: import(
        /* webpackChunkName: 'df-card-product' */ "@/vue/components/overrides/superemmepan/df-card-product/df-card-product.vue"
      ),
      superemmehardis: import(
        /* webpackChunkName: 'df-card-product' */ "@/vue/components/overrides/superemmehardis/df-card-product/df-card-product.vue"
      ),
      migrossmigross: import(
        /* webpackChunkName: 'df-card-product' */ "@/vue/components/overrides/migrossmigross/df-card-product/df-card-product.vue"
      ),
      migrosstrevalli: import(
        /* webpackChunkName: 'df-card-product' */ "@/vue/components/overrides/migrosstrevalli/df-card-product/df-card-product.vue"
      ),
      arcafamila: import(
        /* webpackChunkName: 'df-card-product' */ "@/vue/components/overrides/arcafamila/df-card-product/df-card-product.vue"
      ),
      gmfemi: import(
        /* webpackChunkName: 'df-card-product' */ "@/vue/components/overrides/gmfemi/df-card-product/df-card-product.vue"
      ),
      euronicsbutali: import(
        /* webpackChunkName: 'df-card-product' */ "@/vue/components/overrides/euronicsbutali/df-card-product/df-card-product.vue"
      ),
      euronicsnova: import(
        /* webpackChunkName: 'df-card-product' */ "@/vue/components/overrides/euronicsnova/df-card-product/df-card-product.vue"
      ),
      maxidigalassia: import(
        /* webpackChunkName: 'df-card-product' */ "@/vue/components/overrides/maxidigalassia/df-card-product/df-card-product.vue"
      ),
      maxidifamila: import(
        /* webpackChunkName: 'df-card-product' */ "@/vue/components/overrides/maxidifamila/df-card-product/df-card-product.vue"
      ),
      maxididpiu: import(
        /* webpackChunkName: 'df-card-product' */ "@/vue/components/overrides/maxididpiu/df-card-product/df-card-product.vue"
        ),
      maxidiaeo: import(
        /* webpackChunkName: 'df-card-product' */ "@/vue/components/overrides/maxidiaeo/df-card-product/df-card-product.vue"
      ),
      alfigulliver: import(
        /* webpackChunkName: 'df-card-product' */ "@/vue/components/overrides/alfigulliver/df-card-product/df-card-product.vue"
      ),
      bofrostbofrost: import(
        /* webpackChunkName: 'df-card-product' */ "@/vue/components/overrides/bofrostbofrost/df-card-product/df-card-product.vue"
      ),
    },
    "df-store-selection": {
      default: import(
        /* webpackChunkName: 'df-store-selection' */ "@/vue/components/df-store-selection/df-store-selection.vue"
      ),
    },
    "df-card-product-feautured": {
      default: import(
        /* webpackChunkName: 'df-card-product-feautured' */ "@/vue/components/df-card-product-feautured/df-card-product-feautured.vue"
      ),
      unicommfamila: import(
        /* webpackChunkName: 'df-card-product-feautured' */ "@/vue/components/overrides/unicommfamila/df-card-product-feautured/df-card-product-feautured.vue"
      ),
      unicommemisfero: import(
        /* webpackChunkName: 'df-card-product-feautured' */ "@/vue/components/overrides/unicommemisfero/df-card-product-feautured/df-card-product-feautured.vue"
      ),
      unicommmega: import(
        /* webpackChunkName: 'df-card-product-feautured' */ "@/vue/components/overrides/unicommmega/df-card-product-feautured/df-card-product-feautured.vue"
      ),
      superemmepan: import(
        /* webpackChunkName: 'df-card-product-feautured' */ "@/vue/components/overrides/superemmepan/df-card-product-feautured/df-card-product-feautured.vue"
      ),
      superemmehardis: import(
        /* webpackChunkName: 'df-card-product-feautured' */ "@/vue/components/overrides/superemmehardis/df-card-product-feautured/df-card-product-feautured.vue"
      ),
      migrossmigross: import(
        /* webpackChunkName: 'df-card-product-feautured' */ "@/vue/components/overrides/migrossmigross/df-card-product-feautured/df-card-product-feautured.vue"
      ),
      migrosstrevalli: import(
        /* webpackChunkName: 'df-card-product-feautured' */ "@/vue/components/overrides/migrosstrevalli/df-card-product-feautured/df-card-product-feautured.vue"
      ),
      arcafamila: import(
        /* webpackChunkName: 'df-card-product-feautured' */ "@/vue/components/overrides/arcafamila/df-card-product-feautured/df-card-product-feautured.vue"
      ),
      gmfemi: import(
        /* webpackChunkName: 'df-card-product-feautured' */ "@/vue/components/overrides/gmfemi/df-card-product-feautured/df-card-product-feautured.vue"
      ),
      maxidiaeo: import(
        /* webpackChunkName: 'df-card-product-feautured' */ "@/vue/components/overrides/maxidiaeo/df-card-product-feautured/df-card-product-feautured.vue"
      ),
      maxidifamila: import(
        /* webpackChunkName: 'df-card-product-feautured' */ "@/vue/components/overrides/maxidifamila/df-card-product-feautured/df-card-product-feautured.vue"
      ),
      maxididpiu: import(
        /* webpackChunkName: 'df-card-product-feautured' */ "@/vue/components/overrides/maxididpiu/df-card-product-feautured/df-card-product-feautured.vue"
        ),
      maxidigalassia: import(
        /* webpackChunkName: 'df-card-product-feautured' */ "@/vue/components/overrides/maxidigalassia/df-card-product-feautured/df-card-product-feautured.vue"
      ),
      euronicsbutali: import(
        /* webpackChunkName: 'df-card-product-feautured' */ "@/vue/components/overrides/euronicsbutali/df-card-product-feautured/df-card-product-feautured.vue"
      ),
      euronicsnova: import(
        /* webpackChunkName: 'df-card-product-feautured' */ "@/vue/components/overrides/euronicsnova/df-card-product-feautured/df-card-product-feautured.vue"
      ),
      alfigulliver: import(
        /* webpackChunkName: 'df-card-product-feautured' */ "@/vue/components/overrides/alfigulliver/df-card-product-feautured/df-card-product-feautured.vue"
      ),
    },
    "df-link-button": {
      default: import(
        /* webpackChunkName: 'df-link-button' */ "@/vue/components/df-link-button/df-link-button.vue"
      ),
    },
    "df-product-datails-data": {
      default: import(
        /* webpackChunkName: 'df-product-datails-data' */ "@/vue/components/df-product-datails-data/df-product-datails-data.vue"
      ),
      unicommemi: import(
        /* webpackChunkName: 'df-product-datails-data' */ "@/vue/components/overrides/unicommemi/df-product-datails-data/df-product-datails-data.vue"
      ),
      unicommfamila: import(
        /* webpackChunkName: 'df-product-datails-data' */ "@/vue/components/overrides/unicommfamila/df-product-datails-data/df-product-datails-data.vue"
      ),
      unicommemisfero: import(
        /* webpackChunkName: 'df-product-datails-data' */ "@/vue/components/overrides/unicommemisfero/df-product-datails-data/df-product-datails-data.vue"
      ),
      unicommmega: import(
        /* webpackChunkName: 'df-product-datails-data' */ "@/vue/components/overrides/unicommmega/df-product-datails-data/df-product-datails-data.vue"
      ),
      superemmepan: import(
        /* webpackChunkName: 'df-product-datails-data' */ "@/vue/components/overrides/superemmepan/df-product-datails-data/df-product-datails-data.vue"
      ),
      superemmehardis: import(
        /* webpackChunkName: 'df-product-datails-data' */ "@/vue/components/overrides/superemmehardis/df-product-datails-data/df-product-datails-data.vue"
      ),
      migrossmigross: import(
        /* webpackChunkName: 'df-product-datails-data' */ "@/vue/components/overrides/migrossmigross/df-product-datails-data/df-product-datails-data.vue"
      ),
      migrosstrevalli: import(
        /* webpackChunkName: 'df-product-datails-data' */ "@/vue/components/overrides/migrosstrevalli/df-product-datails-data/df-product-datails-data.vue"
      ),
      arcafamila: import(
        /* webpackChunkName: 'df-product-datails-data' */ "@/vue/components/overrides/arcafamila/df-product-datails-data/df-product-datails-data.vue"
      ),
      gmfemi: import(
        /* webpackChunkName: 'df-product-datails-data' */ "@/vue/components/overrides/gmfemi/df-product-datails-data/df-product-datails-data.vue"
      ),
      euronicsbutali: import(
        /* webpackChunkName: 'df-product-datails-data' */ "@/vue/components/overrides/euronicsbutali/df-product-datails-data/df-product-datails-data.vue"
      ),
      euronicsnova: import(
        /* webpackChunkName: 'df-product-datails-data' */ "@/vue/components/overrides/euronicsnova/df-product-datails-data/df-product-datails-data.vue"
      ),
      maxidigalassia: import(
        /* webpackChunkName: 'df-product-datails-data' */ "@/vue/components/overrides/maxidigalassia/df-product-datails-data/df-product-datails-data.vue"
      ),
      maxidifamila: import(
        /* webpackChunkName: 'df-product-datails-data' */ "@/vue/components/overrides/maxidifamila/df-product-datails-data/df-product-datails-data.vue"
      ),
      maxididpiu: import(
        /* webpackChunkName: 'df-product-datails-data' */ "@/vue/components/overrides/maxididpiu/df-product-datails-data/df-product-datails-data.vue"
        ),
      maxidiaeo: import(
        /* webpackChunkName: 'df-product-datails-data' */ "@/vue/components/overrides/maxidiaeo/df-product-datails-data/df-product-datails-data.vue"
      ),
      alfigulliver: import(
        /* webpackChunkName: 'df-product-datails-data' */ "@/vue/components/overrides/alfigulliver/df-product-datails-data/df-product-datails-data.vue"
      ),
    },
    "df-product-datails-related-products": {
      default: import(
        /* webpackChunkName: 'df-product-datails-related-products' */ "@/vue/components/df-product-datails-related-products/df-product-datails-related-products.vue"
      ),
    },
    "df-product-datails-extra-content": {
      default: import(
        /* webpackChunkName: 'df-product-datails-extra-content' */ "@/vue/components/df-product-datails-extra-content/df-product-datails-extra-content.vue"
      ),
    },
    "df-push-card": {
      default: import(
        /* webpackChunkName: 'df-push-card' */ "@/vue/components/df-push-card/df-push-card.vue"
      ),
    },
    "df-campaign-card": {
      default: import(
        /* webpackChunkName: 'df-campaign-card' */ "@/vue/components/df-campaign-card/df-campaign-card.vue"
      ),
    },
    "df-recipe-card": {
      default: import(
        /* webpackChunkName: 'df-recipe-card' */ "@/vue/components/df-recipe-card/df-recipe-card.vue"
      ),
    },
    "df-section-recipes": {
      default: import(
        /* webpackChunkName: 'df-section-recipes' */ "@/vue/components/df-section-recipes/df-section-recipes.vue"
      ),
    },
    "df-header-mobile": {
      default: import(
        /* webpackChunkName: 'df-header-mobile' */ "@/vue/components/df-header-mobile/df-header-mobile.vue"
      ),
      selexselex: import(
        /* webpackChunkName: 'df-header-mobile' */ "@/vue/components/overrides/selexselex/df-header-mobile/df-header-mobile.vue"
      ),
    },
    "df-section-digital-publication": {
      default: import(
        /* webpackChunkName: 'df-section-digital-publication' */ "@/vue/components/df-section-digital-publication/df-section-digital-publication.vue"
      ),
    },
    "df-card-feautured": {
      default: import(
        /* webpackChunkName: 'df-card-feautured' */ "@/vue/components/df-card-feautured/df-card-feautured.vue"
      ),
    },
    "df-card-special": {
      default: import(
        /* webpackChunkName: 'df-card-special' */ "@/vue/components/df-card-special/df-card-special.vue"
      ),
      unicommemisfero: import(
        /* webpackChunkName: 'df-card-special' */ "@/vue/components/overrides/unicommemisfero/df-card-special/df-card-special.vue"
      ),
    },
    "df-card-banner": {
      default: import(
        /* webpackChunkName: 'df-card-banner' */ "@/vue/components/df-card-banner/df-card-banner.vue"
      ),
    },
    "df-recipe-products": {
      default: import(
        /* webpackChunkName: 'df-recipe-products' */ "@/vue/components/df-page-recipe/df-recipe-products/df-recipe-products.vue"
      ),
    },
    "df-section-attachments": {
      default: import(
        /* webpackChunkName: 'df-section-attachments' */ "@/vue/components/df-section-attachments/df-section-attachments.vue"
      ),
    },
    "df-card-attachment": {
      default: import(
        /* webpackChunkName: 'df-card-attachment' */ "@/vue/components/df-section-attachments/df-card-attachment/df-card-attachment.vue"
      ),
    },
    "df-page-product": {
      default: import(
        /* webpackChunkName: 'df-page-product' */ "@/vue/components/df-page-product/df-page-product.vue"
      ),
      euronicsbutali: import(
        /* webpackChunkName: 'df-page-product' */ "@/vue/components/overrides/euronicsbutali/df-page-product/df-page-product.vue"
      ),
      euronicsnova: import(
        /* webpackChunkName: 'df-page-product' */ "@/vue/components/overrides/euronicsbutali/df-page-product/df-page-product.vue"
      ),
    },
    "df-product-details-images": {
      default: import(
        /* webpackChunkName: 'df-product-details-images' */ "@/vue/components/df-product-details-images/df-product-details-images.vue"
      ),
      gmfemi: import(
        /* webpackChunkName: 'df-product-details-images' */ "@/vue/components/overrides/gmfemi/df-product-details-images/df-product-details-images.vue"
      ),
    },
    "df-page-product-by-code": {
      default: import(
        /* webpackChunkName: 'df-page-product-by-code' */ "@/vue/components/df-page-product-by-code/df-page-product-by-code.vue"
      ),
    },
    "df-private-area": {
      default: import(
        /* webpackChunkName: 'df-private-area' */ "@/vue/components/df-private-area/df-private-area.vue"
      ),
    },
    "df-form-manager": {
      default: import(
        /* webpackChunkName: 'df-form-manager' */ "@/vue/components/df-form-manager/df-form-manager.vue"
      ),
    },
    "df-form-login": {
      default: import(
        /* webpackChunkName: 'df-form-login' */ "@/vue/components/df-private-area/df-form-login/df-form-login.vue"
      ),
    },
    "df-form-password-forgotten": {
      default: import(
        /* webpackChunkName: 'df-form-password-forgotten' */ "@/vue/components/df-private-area/df-form-password-forgotten/df-form-password-forgotten.vue"
      ),
    },
    "df-form-email-change": {
      default: import(
        /* webpackChunkName: 'df-form-email-change' */ "@/vue/components/df-private-area/df-form-email-change/df-form-email-change.vue"
      ),
    },
    "df-form-delete-account": {
      default: import(
        /* webpackChunkName: 'df-form-delete-account' */ "@/vue/components/df-private-area/df-form-delete-account/df-form-delete-account.vue"
      ),
    },
    "df-form-password-change": {
      default: import(
        /* webpackChunkName: 'df-form-password-change' */ "@/vue/components/df-private-area/df-form-password-change/df-form-password-change.vue"
      ),
    },
    "df-page-user-registration": {
      default: import(
        /* webpackChunkName: 'df-page-user-registration' */ "@/vue/components/df-page-user-registration/df-page-user-registration.vue"
      ),
    },
    "df-page-user-profile-edit": {
      default: import(
        /* webpackChunkName: 'df-page-user-profile-edit' */ "@/vue/components/df-page-user-profile-edit/df-page-user-profile-edit.vue"
      ),
    },
    "df-page-password-forgotten": {
      default: import(
        /* webpackChunkName: 'df-page-password-forgotten' */ "@/vue/components/df-page-password-forgotten/df-page-password-forgotten.vue"
      ),
    },
    "df-page-account-expenses": {
      default: import(
        /* webpackChunkName: 'df-page-account-expenses' */ "@/vue/components/df-private-area/df-page-account-expenses/df-page-account-expenses.vue"
      ),
    },
    "df-page-account-cashback": {
      default: import(
        /* webpackChunkName: 'df-page-account-cashback' */ "@/vue/components/df-private-area/df-page-account-cashback/df-page-account-cashback.vue"
        ),
    },
    "df-page-account-card": {
      default: import(
        /* webpackChunkName: 'df-page-account-card' */ "@/vue/components/df-private-area/df-page-account-card/df-page-account-card.vue"
      ),
    },
    "df-page-error": {
      default: import(
        /* webpackChunkName: 'df-page-error' */ "@/vue/components/df-page-error/df-page-error.vue"
      ),
    },
  };

  private static readonly XMLHttpRequestSetup = {
    paramsSerializer: function (params: any): any {
      return Qs.stringify(params, {
        arrayFormat: "repeat",
      });
    },
  };

  public static buildRequestPayload(data: any): any {
    return Object.assign(data, Utils.XMLHttpRequestSetup);
  }

  public static getOriginPath(): string {
    const originPath: HTMLMetaElement = <HTMLMetaElement>(
      document.getElementsByName("_origin_path")[0]
    );
    return originPath ? `${originPath.content}` : `${document.location.origin}`;
  }

  public static getEndpointServerPath(): string {
    const endpointServerPath: HTMLMetaElement = <HTMLMetaElement>(
      document.getElementsByName("_endpoint_server_path")[0]
    );
    return endpointServerPath
      ? `${endpointServerPath.content}`
      : this.getOriginPath();
  }

  public static getAuthorizationData(): string {
    const authorizationData: HTMLMetaElement = <HTMLMetaElement>(
      document.getElementsByName("_authorization_data")[0]
    );
    const bytes = authorizationData
      ? CryptoJS.AES.decrypt(authorizationData.content, "$InterlacedIt0!")
      : null;
    return bytes ? bytes.toString(CryptoJS.enc.Utf8) : null;
  }

  public static getContextPath(absolute?: boolean): string {
    const origin: string = absolute ? `${this.getOriginPath()}` : "";
    const contentPath: HTMLMetaElement = <HTMLMetaElement>(
      document.getElementsByName("_context_path")[0]
    );
    return contentPath && contentPath.content
      ? `${origin}/${contentPath.content}`
      : origin;
  }

  public static getGoogleMapCheckAddressEnable(): boolean {
    const googleMapCheckAddressEnable: HTMLMetaElement = <HTMLMetaElement>(
      document.getElementsByName("_google_map_check_address_enable")[0]
    );
    return googleMapCheckAddressEnable ? googleMapCheckAddressEnable.content === "true" : false;
  }

  public static getGoogleMapCheckAddressMandatory(): boolean {
    const googleMapCheckAddressMandatory: HTMLMetaElement = <HTMLMetaElement>(
      document.getElementsByName("_google_map_check_address_mandatory")[0]
    );
    return googleMapCheckAddressMandatory ? googleMapCheckAddressMandatory.content === "true" : false;
  }

  public static showPrivateAreaCashback(): boolean {
    const showPrivateAreaCashback: HTMLMetaElement = <HTMLMetaElement>(
      document.getElementsByName("_show_private_area_cashback")[0]
    );
    return showPrivateAreaCashback ? showPrivateAreaCashback.content === "true" : false;
  }

  public static getEndpointContextPath(): string {
    const contentPath: HTMLMetaElement = <HTMLMetaElement>(
      document.getElementsByName("_context_path")[0]
    );
    return contentPath && contentPath.content
      ? `${this.getEndpointServerPath()}/${contentPath.content}`
      : this.getEndpointServerPath();
  }

  public static enablePrivateArea(): boolean {
    const contentPath: HTMLMetaElement = <HTMLMetaElement>(
      document.getElementsByName("_enable_private_area")[0]
    );
    return contentPath && contentPath.content
      ? contentPath.content === "true"
      : false;
  }

  public static getPDFDesktopSpreadMode(): string {
    const desktopSpreadMode: HTMLMetaElement = <HTMLMetaElement>(
      document.getElementsByName("_pdf_desktop_spread_mode")[0]
    );
    return desktopSpreadMode && desktopSpreadMode.content
      ? desktopSpreadMode.content
      : null;
  }

  public static getProfileName(): string {
    const profileName: HTMLMetaElement = <HTMLMetaElement>(
      document.getElementsByName("_profile_name")[0]
    );
    return profileName && profileName.content ? profileName.content : null;
  }

  public static getAuthorizedRoutes(): Array<string> {
    const authorizedRoutes: HTMLMetaElement = <HTMLMetaElement>(
      document.getElementsByName("_authorized_routes")[0]
    );
    return authorizedRoutes && authorizedRoutes.content
      ? authorizedRoutes.content.split(",").map((route: string) => route.trim())
      : [];
  }

  public static getAppType(): string {
    const apiPath: HTMLMetaElement = <HTMLMetaElement>(
      document.getElementsByName("_app_type")[0]
    );
    return apiPath ? apiPath.content.toLowerCase() : "";
  }

  public static getApiPath(): string {
    const apiPath: HTMLMetaElement = <HTMLMetaElement>(
      document.getElementsByName("_api_path")[0]
    );
    return apiPath ? apiPath.content : "";
  }

  public static getOverridePath(): string {
    const overridePath: HTMLMetaElement = <HTMLMetaElement>(
      document.getElementsByName("_override_path")[0]
    );
    return overridePath ? overridePath.content : "";
  }

  public static getProfileMode(): string {
    const profileMode: HTMLMetaElement = <HTMLMetaElement>(
      document.getElementsByName("_profile_mode")[0]
    );
    return profileMode ? profileMode.content : "";
  }

  public static getLoadingBackgroundColor(): string {
    const profileMode: HTMLMetaElement = <HTMLMetaElement>(
      document.getElementsByName("_loading_background_color")[0]
    );
    return profileMode ? profileMode.content : "";
  }

  public static showHeaderIcons(): boolean {
    const showHeaderIcons: HTMLMetaElement = <HTMLMetaElement>(
      document.getElementsByName("_show_header_icons")[0]
    );
    return showHeaderIcons ? showHeaderIcons.content === "true" : true;
  }

  public static showBreadcrumb(): boolean {
    const showBreadcrumb: HTMLMetaElement = <HTMLMetaElement>(
      document.getElementsByName("_show_breadcrumb")[0]
    );
    return showBreadcrumb ? showBreadcrumb.content === "true" : true;
  }

  public static showMobileStore(): boolean {
    const showMobileStore: HTMLMetaElement = <HTMLMetaElement>(
      document.getElementsByName("_show_mobile_store")[0]
    );
    return showMobileStore ? showMobileStore.content === "true" : true;
  }

  public static getBasePath(absolute?: boolean): string {
    const origin: string = absolute ? `${this.getOriginPath()}` : "";
    const basePath: HTMLMetaElement = <HTMLMetaElement>(
      document.getElementsByName("_base_path")[0]
    );
    return basePath && basePath.content
      ? `${origin}/${basePath.content}`
      : origin;
  }

  public static getRoutingMode(): RouterMode {
    const routingMode: HTMLMetaElement =
      <HTMLMetaElement>document.getElementsByName("_routing_mode")[0] || null;
    return routingMode ? <RouterMode>routingMode.content : "history";
  }

  public static getPublicPath(): string {
    const publicPath: HTMLMetaElement =
      <HTMLMetaElement>document.getElementsByName("_public_path")[0] || null;

    return publicPath ? `${this.getBasePath(true)}/${publicPath.content}` : ".";
  }

  public static getViewerPdfUrl(): string {
    const viewerPdfUrl: HTMLMetaElement = <HTMLMetaElement>document.getElementsByName("_viewer_pdf_url")[0] || null;

    return viewerPdfUrl?.content || "";
  }

  public static getViewerPdfOptions(position: string): string {
    let viewerPdfOptions: HTMLMetaElement = null;
    switch(position) {
      case 'BANNERS':
        viewerPdfOptions = <HTMLMetaElement>document.getElementsByName("_viewer_pdf_banners_options")[0] || null;
        break;
      case 'PRODUCTS_LIST':
        viewerPdfOptions = <HTMLMetaElement>document.getElementsByName("_viewer_pdf_products_list_options")[0] || null;
        break;
    }
    return viewerPdfOptions?.content || "";
  }

  public static getLanguageCode(): string {
    return document.documentElement !== null
      ? document.documentElement.lang
      : "";
  }

  public static getPermalik(partial: string): string {
    return Utils.getContextPath() + partial;
  }

  public static isAnaliticsTrakerEnabled(): boolean {
    const isAnaliticsTrakerEnabled: HTMLMetaElement =
      <HTMLMetaElement>(
        document.getElementsByName("_analitics_traker_enabled")[0]
      ) || null;
    return isAnaliticsTrakerEnabled
      ? isAnaliticsTrakerEnabled.content === "true"
      : false;
  }

  public static getComponentRemotePath(componentName: string): string {
    const componentsRepositoryPath = `${this.getContextPath(
      true
    )}/files/${this.getApiPath()}/components/`;
    return `${componentsRepositoryPath}${componentName}`;
  }

  public static isArrayEquals(
    arrayOne: Array<any>,
    arrayTwo: Array<any>
  ): boolean {
    if (!arrayOne || !arrayTwo) {
      return false;
    }

    if (arrayOne.length !== arrayTwo.length) {
      return false;
    }

    for (let i = 0, l = arrayOne.length; i < l; i++) {
      if (arrayOne[i] instanceof Array && arrayTwo[i] instanceof Array) {
        if (!this.isArrayEquals(arrayOne[i], arrayTwo[i])) {
          return false;
        }
      } else if (arrayOne[i] !== arrayTwo[i]) {
        return false;
      }
    }
    return true;
  }

  public static downloadFile(
    downloadData: ArrayBuffer,
    filename: string,
    contentType: string
  ): void {
    (<any>window).cordova || false
      ? Utils.downloadFileCordova(downloadData, filename, contentType)
      : Utils.downloadFileWeb(downloadData, filename, contentType);
  }

  public static downloadFileWeb(
    downloadData: ArrayBuffer,
    filename: string,
    contentType: string
  ): void {
    const data = new Blob([downloadData], { type: contentType });
    const link = document.createElement("a");
    document.body.appendChild(link);
    link.href = window.URL.createObjectURL(data);
    link.download = filename;
    link.click();
    window.URL.revokeObjectURL(link.href);
    link.remove();
  }

  public static downloadFileCordova(
    downloadData: ArrayBuffer,
    filename: string,
    contentType: string
  ): void {
    const cordova: any = (<any>window).cordova || null;

    if (cordova && cordova.platformId !== "browser") {
      document.addEventListener("deviceready", function () {
        const storageLocation =
          cordova.platformId === "android"
            ? cordova.file.externalDataDirectory
            : cordova.file.tempDirectory; //cordova.file.cacheDirectory;
        console.log({
          storageLocation: storageLocation,
          platform: cordova.platformId,
        });
        // eslint-disable-next-line
        (<any>window).resolveLocalFileSystemURL(
          storageLocation,
          (dir: any) => {
            console.log("Native URI: " + dir.toURL());
            console.log("cdvfile URI: " + dir.toInternalURL());
            // eslint-disable-next-line
            dir.getFile(
              filename,
              { create: true },
              (file: any) => {
                console.log(file);
                // eslint-disable-next-line
                file.createWriter(
                  (fileWriter: any) => {
                    const data = new Blob([downloadData], {
                      type: contentType,
                    });
                    console.log(data);
                    fileWriter.onwriteend = function () {
                      cordova.plugins.fileOpener2.open(
                        file.nativeURL,
                        contentType,
                        {
                          error: function error(err: any) {
                            console.error(err);
                            alert(`Unable to open: ${err.message}`);
                          },
                          success: function success() {
                            console.log("success with opening the file");
                          },
                        }
                      );
                    };
                    fileWriter.onerror = function (err: any) {
                      alert("Unable to download");
                      console.error(err);
                    };
                    fileWriter.write(data);
                  },
                  (err: any) => {
                    // failed
                    alert("Unable to download");
                    console.error(err);
                    // eslint-disable-next-line
                  }
                );
              },
              function (err: any) {
                alert("Unable to download");
                console.error(err);
                // eslint-disable-next-line
              }
            );
          },
          (err: any) => {
            alert("Unable to download");
            console.error(err);
            // eslint-disable-next-line
          }
        );
      });
    }
  }

  public static getMimeFromExtension(extension: string) {
    const mimeTypes: Array<any> = [
      ["323", "text/h323"],
      ["3g2", "video/3gpp2"],
      ["3gp", "video/3gpp"],
      ["3gp2", "video/3gpp2"],
      ["3gpp", "video/3gpp"],
      ["7z", "application/x-7z-compressed"],
      ["aa", "audio/audible"],
      ["AAC", "audio/aac"],
      ["aaf", "application/octet-stream"],
      ["aax", "audio/vnd.audible.aax"],
      ["ac3", "audio/ac3"],
      ["aca", "application/octet-stream"],
      ["accda", "application/msaccess.addin"],
      ["accdb", "application/msaccess"],
      ["accdc", "application/msaccess.cab"],
      ["accde", "application/msaccess"],
      ["accdr", "application/msaccess.runtime"],
      ["accdt", "application/msaccess"],
      ["accdw", "application/msaccess.webapplication"],
      ["accft", "application/msaccess.ftemplate"],
      ["acx", "application/internet-property-stream"],
      ["AddIn", "text/xml"],
      ["ade", "application/msaccess"],
      ["adobebridge", "application/x-bridge-url"],
      ["adp", "application/msaccess"],
      ["ADT", "audio/vnd.dlna.adts"],
      ["ADTS", "audio/aac"],
      ["afm", "application/octet-stream"],
      ["ai", "application/postscript"],
      ["aif", "audio/x-aiff"],
      ["aifc", "audio/aiff"],
      ["aiff", "audio/aiff"],
      ["air", "application/vnd.adobe.air-application-installer-package+zip"],
      ["amc", "application/x-mpeg"],
      ["application", "application/x-ms-application"],
      ["art", "image/x-jg"],
      ["asa", "application/xml"],
      ["asax", "application/xml"],
      ["ascx", "application/xml"],
      ["asd", "application/octet-stream"],
      ["asf", "video/x-ms-asf"],
      ["ashx", "application/xml"],
      ["asi", "application/octet-stream"],
      ["asm", "text/plain"],
      ["asmx", "application/xml"],
      ["aspx", "application/xml"],
      ["asr", "video/x-ms-asf"],
      ["asx", "video/x-ms-asf"],
      ["atom", "application/atom+xml"],
      ["au", "audio/basic"],
      ["avi", "video/x-msvideo"],
      ["axs", "application/olescript"],
      ["bas", "text/plain"],
      ["bcpio", "application/x-bcpio"],
      ["bin", "application/octet-stream"],
      ["bmp", "image/bmp"],
      ["c", "text/plain"],
      ["cab", "application/octet-stream"],
      ["caf", "audio/x-caf"],
      ["calx", "application/vnd.ms-office.calx"],
      ["cat", "application/vnd.ms-pki.seccat"],
      ["cc", "text/plain"],
      ["cd", "text/plain"],
      ["cdda", "audio/aiff"],
      ["cdf", "application/x-cdf"],
      ["cer", "application/x-x509-ca-cert"],
      ["chm", "application/octet-stream"],
      ["class", "application/x-java-applet"],
      ["clp", "application/x-msclip"],
      ["cmx", "image/x-cmx"],
      ["cnf", "text/plain"],
      ["cod", "image/cis-cod"],
      ["config", "application/xml"],
      ["contact", "text/x-ms-contact"],
      ["coverage", "application/xml"],
      ["cpio", "application/x-cpio"],
      ["cpp", "text/plain"],
      ["crd", "application/x-mscardfile"],
      ["crl", "application/pkix-crl"],
      ["crt", "application/x-x509-ca-cert"],
      ["cs", "text/plain"],
      ["csdproj", "text/plain"],
      ["csh", "application/x-csh"],
      ["csproj", "text/plain"],
      ["css", "text/css"],
      ["csv", "text/csv"],
      ["cur", "application/octet-stream"],
      ["cxx", "text/plain"],
      ["dat", "application/octet-stream"],
      ["datasource", "application/xml"],
      ["dbproj", "text/plain"],
      ["dcr", "application/x-director"],
      ["def", "text/plain"],
      ["deploy", "application/octet-stream"],
      ["der", "application/x-x509-ca-cert"],
      ["dgml", "application/xml"],
      ["dib", "image/bmp"],
      ["dif", "video/x-dv"],
      ["dir", "application/x-director"],
      ["disco", "text/xml"],
      ["dll", "application/x-msdownload"],
      ["dll.config", "text/xml"],
      ["dlm", "text/dlm"],
      ["doc", "application/msword"],
      ["docm", "application/vnd.ms-word.document.macroEnabled.12"],
      [
        "docx",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ],
      ["dot", "application/msword"],
      ["dotm", "application/vnd.ms-word.template.macroEnabled.12"],
      [
        "dotx",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
      ],
      ["dsp", "application/octet-stream"],
      ["dsw", "text/plain"],
      ["dtd", "text/xml"],
      ["dtsConfig", "text/xml"],
      ["dv", "video/x-dv"],
      ["dvi", "application/x-dvi"],
      ["dwf", "drawing/x-dwf"],
      ["dwp", "application/octet-stream"],
      ["dxr", "application/x-director"],
      ["eml", "message/rfc822"],
      ["emz", "application/octet-stream"],
      ["eot", "application/octet-stream"],
      ["eps", "application/postscript"],
      ["etl", "application/etl"],
      ["etx", "text/x-setext"],
      ["evy", "application/envoy"],
      ["exe", "application/octet-stream"],
      ["exe.config", "text/xml"],
      ["fdf", "application/vnd.fdf"],
      ["fif", "application/fractals"],
      ["filters", "Application/xml"],
      ["fla", "application/octet-stream"],
      ["flr", "x-world/x-vrml"],
      ["flv", "video/x-flv"],
      ["fsscript", "application/fsharp-script"],
      ["fsx", "application/fsharp-script"],
      ["generictest", "application/xml"],
      ["gif", "image/gif"],
      ["group", "text/x-ms-group"],
      ["gsm", "audio/x-gsm"],
      ["gtar", "application/x-gtar"],
      ["gz", "application/x-gzip"],
      ["h", "text/plain"],
      ["hdf", "application/x-hdf"],
      ["hdml", "text/x-hdml"],
      ["hhc", "application/x-oleobject"],
      ["hhk", "application/octet-stream"],
      ["hhp", "application/octet-stream"],
      ["hlp", "application/winhlp"],
      ["hpp", "text/plain"],
      ["hqx", "application/mac-binhex40"],
      ["hta", "application/hta"],
      ["htc", "text/x-component"],
      ["htm", "text/html"],
      ["html", "text/html"],
      ["htt", "text/webviewhtml"],
      ["hxa", "application/xml"],
      ["hxc", "application/xml"],
      ["hxd", "application/octet-stream"],
      ["hxe", "application/xml"],
      ["hxf", "application/xml"],
      ["hxh", "application/octet-stream"],
      ["hxi", "application/octet-stream"],
      ["hxk", "application/xml"],
      ["hxq", "application/octet-stream"],
      ["hxr", "application/octet-stream"],
      ["hxs", "application/octet-stream"],
      ["hxt", "text/html"],
      ["hxv", "application/xml"],
      ["hxw", "application/octet-stream"],
      ["hxx", "text/plain"],
      ["i", "text/plain"],
      ["ico", "image/x-icon"],
      ["ics", "application/octet-stream"],
      ["idl", "text/plain"],
      ["ief", "image/ief"],
      ["iii", "application/x-iphone"],
      ["inc", "text/plain"],
      ["inf", "application/octet-stream"],
      ["inl", "text/plain"],
      ["ins", "application/x-internet-signup"],
      ["ipa", "application/x-itunes-ipa"],
      ["ipg", "application/x-itunes-ipg"],
      ["ipproj", "text/plain"],
      ["ipsw", "application/x-itunes-ipsw"],
      ["iqy", "text/x-ms-iqy"],
      ["isp", "application/x-internet-signup"],
      ["ite", "application/x-itunes-ite"],
      ["itlp", "application/x-itunes-itlp"],
      ["itms", "application/x-itunes-itms"],
      ["itpc", "application/x-itunes-itpc"],
      ["IVF", "video/x-ivf"],
      ["jar", "application/java-archive"],
      ["java", "application/octet-stream"],
      ["jck", "application/liquidmotion"],
      ["jcz", "application/liquidmotion"],
      ["jfif", "image/pjpeg"],
      ["jnlp", "application/x-java-jnlp-file"],
      ["jpb", "application/octet-stream"],
      ["jpe", "image/jpeg"],
      ["jpeg", "image/jpeg"],
      ["jpg", "image/jpeg"],
      ["js", "application/x-javascript"],
      ["json", "application/json"],
      ["jsx", "text/jscript"],
      ["jsxbin", "text/plain"],
      ["latex", "application/x-latex"],
      ["library-ms", "application/windows-library+xml"],
      ["lit", "application/x-ms-reader"],
      ["loadtest", "application/xml"],
      ["lpk", "application/octet-stream"],
      ["lsf", "video/x-la-asf"],
      ["lst", "text/plain"],
      ["lsx", "video/x-la-asf"],
      ["lzh", "application/octet-stream"],
      ["m13", "application/x-msmediaview"],
      ["m14", "application/x-msmediaview"],
      ["m1v", "video/mpeg"],
      ["m2t", "video/vnd.dlna.mpeg-tts"],
      ["m2ts", "video/vnd.dlna.mpeg-tts"],
      ["m2v", "video/mpeg"],
      ["m3u", "audio/x-mpegurl"],
      ["m3u8", "audio/x-mpegurl"],
      ["m4a", "audio/m4a"],
      ["m4b", "audio/m4b"],
      ["m4p", "audio/m4p"],
      ["m4r", "audio/x-m4r"],
      ["m4v", "video/x-m4v"],
      ["mac", "image/x-macpaint"],
      ["mak", "text/plain"],
      ["man", "application/x-troff-man"],
      ["manifest", "application/x-ms-manifest"],
      ["map", "text/plain"],
      ["master", "application/xml"],
      ["mda", "application/msaccess"],
      ["mdb", "application/x-msaccess"],
      ["mde", "application/msaccess"],
      ["mdp", "application/octet-stream"],
      ["me", "application/x-troff-me"],
      ["mfp", "application/x-shockwave-flash"],
      ["mht", "message/rfc822"],
      ["mhtml", "message/rfc822"],
      ["mid", "audio/mid"],
      ["midi", "audio/mid"],
      ["mix", "application/octet-stream"],
      ["mk", "text/plain"],
      ["mmf", "application/x-smaf"],
      ["mno", "text/xml"],
      ["mny", "application/x-msmoney"],
      ["mod", "video/mpeg"],
      ["mov", "video/quicktime"],
      ["movie", "video/x-sgi-movie"],
      ["mp2", "video/mpeg"],
      ["mp2v", "video/mpeg"],
      ["mp3", "audio/mpeg"],
      ["mp4", "video/mp4"],
      ["mp4v", "video/mp4"],
      ["mpa", "video/mpeg"],
      ["mpe", "video/mpeg"],
      ["mpeg", "video/mpeg"],
      ["mpf", "application/vnd.ms-mediapackage"],
      ["mpg", "video/mpeg"],
      ["mpp", "application/vnd.ms-project"],
      ["mpv2", "video/mpeg"],
      ["mqv", "video/quicktime"],
      ["ms", "application/x-troff-ms"],
      ["msi", "application/octet-stream"],
      ["mso", "application/octet-stream"],
      ["mts", "video/vnd.dlna.mpeg-tts"],
      ["mtx", "application/xml"],
      ["mvb", "application/x-msmediaview"],
      ["mvc", "application/x-miva-compiled"],
      ["mxp", "application/x-mmxp"],
      ["nc", "application/x-netcdf"],
      ["nsc", "video/x-ms-asf"],
      ["nws", "message/rfc822"],
      ["ocx", "application/octet-stream"],
      ["oda", "application/oda"],
      ["odc", "text/x-ms-odc"],
      ["odh", "text/plain"],
      ["odl", "text/plain"],
      ["odp", "application/vnd.oasis.opendocument.presentation"],
      ["ods", "application/oleobject"],
      ["odt", "application/vnd.oasis.opendocument.text"],
      ["one", "application/onenote"],
      ["onea", "application/onenote"],
      ["onepkg", "application/onenote"],
      ["onetmp", "application/onenote"],
      ["onetoc", "application/onenote"],
      ["onetoc2", "application/onenote"],
      ["orderedtest", "application/xml"],
      ["osdx", "application/opensearchdescription+xml"],
      ["p10", "application/pkcs10"],
      ["p12", "application/x-pkcs12"],
      ["p7b", "application/x-pkcs7-certificates"],
      ["p7c", "application/pkcs7-mime"],
      ["p7m", "application/pkcs7-mime"],
      ["p7r", "application/x-pkcs7-certreqresp"],
      ["p7s", "application/pkcs7-signature"],
      ["pbm", "image/x-portable-bitmap"],
      ["pcast", "application/x-podcast"],
      ["pct", "image/pict"],
      ["pcx", "application/octet-stream"],
      ["pcz", "application/octet-stream"],
      ["pdf", "application/pdf"],
      ["pfb", "application/octet-stream"],
      ["pfm", "application/octet-stream"],
      ["pfx", "application/x-pkcs12"],
      ["pgm", "image/x-portable-graymap"],
      ["pic", "image/pict"],
      ["pict", "image/pict"],
      ["pkgdef", "text/plain"],
      ["pkgundef", "text/plain"],
      ["pko", "application/vnd.ms-pki.pko"],
      ["pls", "audio/scpls"],
      ["pma", "application/x-perfmon"],
      ["pmc", "application/x-perfmon"],
      ["pml", "application/x-perfmon"],
      ["pmr", "application/x-perfmon"],
      ["pmw", "application/x-perfmon"],
      ["png", "image/png"],
      ["pnm", "image/x-portable-anymap"],
      ["pnt", "image/x-macpaint"],
      ["pntg", "image/x-macpaint"],
      ["pnz", "image/png"],
      ["pot", "application/vnd.ms-powerpoint"],
      ["potm", "application/vnd.ms-powerpoint.template.macroEnabled.12"],
      [
        "potx",
        "application/vnd.openxmlformats-officedocument.presentationml.template",
      ],
      ["ppa", "application/vnd.ms-powerpoint"],
      ["ppam", "application/vnd.ms-powerpoint.addin.macroEnabled.12"],
      ["ppm", "image/x-portable-pixmap"],
      ["pps", "application/vnd.ms-powerpoint"],
      ["ppsm", "application/vnd.ms-powerpoint.slideshow.macroEnabled.12"],
      [
        "ppsx",
        "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
      ],
      ["ppt", "application/vnd.ms-powerpoint"],
      ["pptm", "application/vnd.ms-powerpoint.presentation.macroEnabled.12"],
      [
        "pptx",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      ],
      ["prf", "application/pics-rules"],
      ["prm", "application/octet-stream"],
      ["prx", "application/octet-stream"],
      ["ps", "application/postscript"],
      ["psc1", "application/PowerShell"],
      ["psd", "application/octet-stream"],
      ["psess", "application/xml"],
      ["psm", "application/octet-stream"],
      ["psp", "application/octet-stream"],
      ["pub", "application/x-mspublisher"],
      ["pwz", "application/vnd.ms-powerpoint"],
      ["qht", "text/x-html-insertion"],
      ["qhtm", "text/x-html-insertion"],
      ["qt", "video/quicktime"],
      ["qti", "image/x-quicktime"],
      ["qtif", "image/x-quicktime"],
      ["qtl", "application/x-quicktimeplayer"],
      ["qxd", "application/octet-stream"],
      ["ra", "audio/x-pn-realaudio"],
      ["ram", "audio/x-pn-realaudio"],
      ["rar", "application/octet-stream"],
      ["ras", "image/x-cmu-raster"],
      ["rat", "application/rat-file"],
      ["rc", "text/plain"],
      ["rc2", "text/plain"],
      ["rct", "text/plain"],
      ["rdlc", "application/xml"],
      ["resx", "application/xml"],
      ["rf", "image/vnd.rn-realflash"],
      ["rgb", "image/x-rgb"],
      ["rgs", "text/plain"],
      ["rm", "application/vnd.rn-realmedia"],
      ["rmi", "audio/mid"],
      ["rmp", "application/vnd.rn-rn_music_package"],
      ["roff", "application/x-troff"],
      ["rpm", "audio/x-pn-realaudio-plugin"],
      ["rqy", "text/x-ms-rqy"],
      ["rtf", "application/rtf"],
      ["rtx", "text/richtext"],
      ["ruleset", "application/xml"],
      ["s", "text/plain"],
      ["safariextz", "application/x-safari-safariextz"],
      ["scd", "application/x-msschedule"],
      ["sct", "text/scriptlet"],
      ["sd2", "audio/x-sd2"],
      ["sdp", "application/sdp"],
      ["sea", "application/octet-stream"],
      ["searchConnector-ms", "application/windows-search-connector+xml"],
      ["setpay", "application/set-payment-initiation"],
      ["setreg", "application/set-registration-initiation"],
      ["settings", "application/xml"],
      ["sgimb", "application/x-sgimb"],
      ["sgml", "text/sgml"],
      ["sh", "application/x-sh"],
      ["shar", "application/x-shar"],
      ["shtml", "text/html"],
      ["sit", "application/x-stuffit"],
      ["sitemap", "application/xml"],
      ["skin", "application/xml"],
      ["sldm", "application/vnd.ms-powerpoint.slide.macroEnabled.12"],
      [
        "sldx",
        "application/vnd.openxmlformats-officedocument.presentationml.slide",
      ],
      ["slk", "application/vnd.ms-excel"],
      ["sln", "text/plain"],
      ["slupkg-ms", "application/x-ms-license"],
      ["smd", "audio/x-smd"],
      ["smi", "application/octet-stream"],
      ["smx", "audio/x-smd"],
      ["smz", "audio/x-smd"],
      ["snd", "audio/basic"],
      ["snippet", "application/xml"],
      ["snp", "application/octet-stream"],
      ["sol", "text/plain"],
      ["sor", "text/plain"],
      ["spc", "application/x-pkcs7-certificates"],
      ["spl", "application/futuresplash"],
      ["src", "application/x-wais-source"],
      ["srf", "text/plain"],
      ["SSISDeploymentManifest", "text/xml"],
      ["ssm", "application/streamingmedia"],
      ["sst", "application/vnd.ms-pki.certstore"],
      ["stl", "application/vnd.ms-pki.stl"],
      ["sv4cpio", "application/x-sv4cpio"],
      ["sv4crc", "application/x-sv4crc"],
      ["svc", "application/xml"],
      ["swf", "application/x-shockwave-flash"],
      ["t", "application/x-troff"],
      ["tar", "application/x-tar"],
      ["tcl", "application/x-tcl"],
      ["testrunconfig", "application/xml"],
      ["testsettings", "application/xml"],
      ["tex", "application/x-tex"],
      ["texi", "application/x-texinfo"],
      ["texinfo", "application/x-texinfo"],
      ["tgz", "application/x-compressed"],
      ["thmx", "application/vnd.ms-officetheme"],
      ["thn", "application/octet-stream"],
      ["tif", "image/tiff"],
      ["tiff", "image/tiff"],
      ["tlh", "text/plain"],
      ["tli", "text/plain"],
      ["toc", "application/octet-stream"],
      ["tr", "application/x-troff"],
      ["trm", "application/x-msterminal"],
      ["trx", "application/xml"],
      ["ts", "video/vnd.dlna.mpeg-tts"],
      ["tsv", "text/tab-separated-values"],
      ["ttf", "application/octet-stream"],
      ["tts", "video/vnd.dlna.mpeg-tts"],
      ["txt", "text/plain"],
      ["u32", "application/octet-stream"],
      ["uls", "text/iuls"],
      ["user", "text/plain"],
      ["ustar", "application/x-ustar"],
      ["vb", "text/plain"],
      ["vbdproj", "text/plain"],
      ["vbk", "video/mpeg"],
      ["vbproj", "text/plain"],
      ["vbs", "text/vbscript"],
      ["vcf", "text/x-vcard"],
      ["vcproj", "Application/xml"],
      ["vcs", "text/plain"],
      ["vcxproj", "Application/xml"],
      ["vddproj", "text/plain"],
      ["vdp", "text/plain"],
      ["vdproj", "text/plain"],
      ["vdx", "application/vnd.ms-visio.viewer"],
      ["vml", "text/xml"],
      ["vscontent", "application/xml"],
      ["vsct", "text/xml"],
      ["vsd", "application/vnd.visio"],
      ["vsi", "application/ms-vsi"],
      ["vsix", "application/vsix"],
      ["vsixlangpack", "text/xml"],
      ["vsixmanifest", "text/xml"],
      ["vsmdi", "application/xml"],
      ["vspscc", "text/plain"],
      ["vss", "application/vnd.visio"],
      ["vsscc", "text/plain"],
      ["vssettings", "text/xml"],
      ["vssscc", "text/plain"],
      ["vst", "application/vnd.visio"],
      ["vstemplate", "text/xml"],
      ["vsto", "application/x-ms-vsto"],
      ["vsw", "application/vnd.visio"],
      ["vsx", "application/vnd.visio"],
      ["vtx", "application/vnd.visio"],
      ["wav", "audio/wav"],
      ["wave", "audio/wav"],
      ["wax", "audio/x-ms-wax"],
      ["wbk", "application/msword"],
      ["wbmp", "image/vnd.wap.wbmp"],
      ["wcm", "application/vnd.ms-works"],
      ["wdb", "application/vnd.ms-works"],
      ["wdp", "image/vnd.ms-photo"],
      ["webarchive", "application/x-safari-webarchive"],
      ["webtest", "application/xml"],
      ["wiq", "application/xml"],
      ["wiz", "application/msword"],
      ["wks", "application/vnd.ms-works"],
      ["WLMP", "application/wlmoviemaker"],
      ["wlpginstall", "application/x-wlpg-detect"],
      ["wlpginstall3", "application/x-wlpg3-detect"],
      ["wm", "video/x-ms-wm"],
      ["wma", "audio/x-ms-wma"],
      ["wmd", "application/x-ms-wmd"],
      ["wmf", "application/x-msmetafile"],
      ["wml", "text/vnd.wap.wml"],
      ["wmlc", "application/vnd.wap.wmlc"],
      ["wmls", "text/vnd.wap.wmlscript"],
      ["wmlsc", "application/vnd.wap.wmlscriptc"],
      ["wmp", "video/x-ms-wmp"],
      ["wmv", "video/x-ms-wmv"],
      ["wmx", "video/x-ms-wmx"],
      ["wmz", "application/x-ms-wmz"],
      ["wpl", "application/vnd.ms-wpl"],
      ["wps", "application/vnd.ms-works"],
      ["wri", "application/x-mswrite"],
      ["wrl", "x-world/x-vrml"],
      ["wrz", "x-world/x-vrml"],
      ["wsc", "text/scriptlet"],
      ["wsdl", "text/xml"],
      ["wvx", "video/x-ms-wvx"],
      ["x", "application/directx"],
      ["xaf", "x-world/x-vrml"],
      ["xaml", "application/xaml+xml"],
      ["xap", "application/x-silverlight-app"],
      ["xbap", "application/x-ms-xbap"],
      ["xbm", "image/x-xbitmap"],
      ["xdr", "text/plain"],
      ["xht", "application/xhtml+xml"],
      ["xhtml", "application/xhtml+xml"],
      ["xla", "application/vnd.ms-excel"],
      ["xlam", "application/vnd.ms-excel.addin.macroEnabled.12"],
      ["xlc", "application/vnd.ms-excel"],
      ["xld", "application/vnd.ms-excel"],
      ["xlk", "application/vnd.ms-excel"],
      ["xll", "application/vnd.ms-excel"],
      ["xlm", "application/vnd.ms-excel"],
      ["xls", "application/vnd.ms-excel"],
      ["xlsb", "application/vnd.ms-excel.sheet.binary.macroEnabled.12"],
      ["xlsm", "application/vnd.ms-excel.sheet.macroEnabled.12"],
      [
        "xlsx",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ],
      ["xlt", "application/vnd.ms-excel"],
      ["xltm", "application/vnd.ms-excel.template.macroEnabled.12"],
      [
        "xltx",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
      ],
      ["xlw", "application/vnd.ms-excel"],
      ["xml", "text/xml"],
      ["xmta", "application/xml"],
      ["xof", "x-world/x-vrml"],
      ["XOML", "text/plain"],
      ["xpm", "image/x-xpixmap"],
      ["xps", "application/vnd.ms-xpsdocument"],
      ["xrm-ms", "text/xml"],
      ["xsc", "application/xml"],
      ["xsd", "text/xml"],
      ["xsf", "text/xml"],
      ["xsl", "text/xml"],
      ["xslt", "text/xml"],
      ["xsn", "application/octet-stream"],
      ["xss", "application/xml"],
      ["xtp", "application/octet-stream"],
      ["xwd", "image/x-xwindowdump"],
      ["z", "application/x-compress"],
      ["zip", "application/x-zip-compressed"],
    ];

    const mimeType: Array<string> = mimeTypes.find(
      (mimeType: Array<string>) => mimeType[0] === extension
    );
    return mimeType ? mimeType[1] : "application/octet-stream";
  }

  public static setWorking(isWorking: boolean, element?: HTMLElement): void {
    element =
      element || <HTMLElement>document.documentElement.querySelector("body");

    if (element !== null) {
      isWorking
        ? element.classList.add("ui-working")
        : element.classList.remove("ui-working");
    }
  }

  public static killIubenda(isWorking: boolean): void {
    const element: HTMLElement = <HTMLElement>(
      document.documentElement.querySelector("body")
    );

    if (element !== null) {
      isWorking
        ? element.classList.add("hide-iubenda")
        : element.classList.remove("hide-iubenda");
    }
  }

  public static unstringify(value: any, sep: any, eq: any): Array<any> {
    if (typeof value === "string") {
      const properties = decodeURIComponent(value).split(sep);
      const arr = [];
      for (let i = 0; i < properties.length; i++) {
        const prop = properties[i].split(eq);
        const obj: any = {};
        obj[prop[0]] = prop[1];
        arr.push(obj);
      }
      return arr;
    } else {
      return value;
    }
  }

  public static getLocaleDateString(hours: boolean): string {
    const formats: any = {
      "ar-SA": "dd/MM/yy",
      "bg-BG": "dd.M.yyyy",
      "ca-ES": "dd/MM/yyyy",
      "zh-TW": "yyyy/M/d",
      "cs-CZ": "d.M.yyyy",
      "da-DK": "dd-MM-yyyy",
      "de-DE": "dd.MM.yyyy",
      "el-GR": "d/M/yyyy",
      "en-US": "M/d/yyyy",
      "fi-FI": "d.M.yyyy",
      "fr-FR": "dd/MM/yyyy",
      "he-IL": "dd/MM/yyyy",
      "hu-HU": "yyyy. MM. dd.",
      "is-IS": "d.M.yyyy",
      "it-IT": "dd/MM/yyyy",
      "ja-JP": "yyyy/MM/dd",
      "ko-KR": "yyyy-MM-dd",
      "nl-NL": "d-M-yyyy",
      "nb-NO": "dd.MM.yyyy",
      "pl-PL": "yyyy-MM-dd",
      "pt-BR": "d/M/yyyy",
      "ro-RO": "dd.MM.yyyy",
      "ru-RU": "dd.MM.yyyy",
      "hr-HR": "d.M.yyyy",
      "sk-SK": "d. M. yyyy",
      "sq-AL": "yyyy-MM-dd",
      "sv-SE": "yyyy-MM-dd",
      "th-TH": "d/M/yyyy",
      "tr-TR": "dd.MM.yyyy",
      "ur-PK": "dd/MM/yyyy",
      "id-ID": "dd/MM/yyyy",
      "uk-UA": "dd.MM.yyyy",
      "be-BY": "dd.MM.yyyy",
      "sl-SI": "d.M.yyyy",
      "et-EE": "d.MM.yyyy",
      "lv-LV": "yyyy.MM.dd.",
      "lt-LT": "yyyy.MM.dd",
      "fa-IR": "MM/dd/yyyy",
      "vi-VN": "dd/MM/yyyy",
      "hy-AM": "dd.MM.yyyy",
      "az-Latn-AZ": "dd.MM.yyyy",
      "eu-ES": "yyyy/MM/dd",
      "mk-MK": "dd.MM.yyyy",
      "af-ZA": "yyyy/MM/dd",
      "ka-GE": "dd.MM.yyyy",
      "fo-FO": "dd-MM-yyyy",
      "hi-IN": "dd-MM-yyyy",
      "ms-MY": "dd/MM/yyyy",
      "kk-KZ": "dd.MM.yyyy",
      "ky-KG": "dd.MM.yy",
      "sw-KE": "M/d/yyyy",
      "uz-Latn-UZ": "dd/MM yyyy",
      "tt-RU": "dd.MM.yyyy",
      "pa-IN": "dd-MM-yy",
      "gu-IN": "dd-MM-yy",
      "ta-IN": "dd-MM-yyyy",
      "te-IN": "dd-MM-yy",
      "kn-IN": "dd-MM-yy",
      "mr-IN": "dd-MM-yyyy",
      "sa-IN": "dd-MM-yyyy",
      "mn-MN": "yy.MM.dd",
      "gl-ES": "dd/MM/yy",
      "kok-IN": "dd-MM-yyyy",
      "syr-SY": "dd/MM/yyyy",
      "dv-MV": "dd/MM/yy",
      "ar-IQ": "dd/MM/yyyy",
      "zh-CN": "yyyy/M/d",
      "de-CH": "dd.MM.yyyy",
      "en-GB": "dd/MM/yyyy",
      "es-MX": "dd/MM/yyyy",
      "fr-BE": "d/MM/yyyy",
      "it-CH": "dd.MM.yyyy",
      "nl-BE": "d/MM/yyyy",
      "nn-NO": "dd.MM.yyyy",
      "pt-PT": "dd-MM-yyyy",
      "sr-Latn-CS": "d.M.yyyy",
      "sv-FI": "d.M.yyyy",
      "az-Cyrl-AZ": "dd.MM.yyyy",
      "ms-BN": "dd/MM/yyyy",
      "uz-Cyrl-UZ": "dd.MM.yyyy",
      "ar-EG": "dd/MM/yyyy",
      "zh-HK": "d/M/yyyy",
      "de-AT": "dd.MM.yyyy",
      "en-AU": "d/MM/yyyy",
      "es-ES": "dd/MM/yyyy",
      "fr-CA": "yyyy-MM-dd",
      "sr-Cyrl-CS": "d.M.yyyy",
      "ar-LY": "dd/MM/yyyy",
      "zh-SG": "d/M/yyyy",
      "de-LU": "dd.MM.yyyy",
      "en-CA": "dd/MM/yyyy",
      "es-GT": "dd/MM/yyyy",
      "fr-CH": "dd.MM.yyyy",
      "ar-DZ": "dd-MM-yyyy",
      "zh-MO": "d/M/yyyy",
      "de-LI": "dd.MM.yyyy",
      "en-NZ": "d/MM/yyyy",
      "es-CR": "dd/MM/yyyy",
      "fr-LU": "dd/MM/yyyy",
      "ar-MA": "dd-MM-yyyy",
      "en-IE": "dd/MM/yyyy",
      "es-PA": "MM/dd/yyyy",
      "fr-MC": "dd/MM/yyyy",
      "ar-TN": "dd-MM-yyyy",
      "en-ZA": "yyyy/MM/dd",
      "es-DO": "dd/MM/yyyy",
      "ar-OM": "dd/MM/yyyy",
      "en-JM": "dd/MM/yyyy",
      "es-VE": "dd/MM/yyyy",
      "ar-YE": "dd/MM/yyyy",
      "en-029": "MM/dd/yyyy",
      "es-CO": "dd/MM/yyyy",
      "ar-SY": "dd/MM/yyyy",
      "en-BZ": "dd/MM/yyyy",
      "es-PE": "dd/MM/yyyy",
      "ar-JO": "dd/MM/yyyy",
      "en-TT": "dd/MM/yyyy",
      "es-AR": "dd/MM/yyyy",
      "ar-LB": "dd/MM/yyyy",
      "en-ZW": "M/d/yyyy",
      "es-EC": "dd/MM/yyyy",
      "ar-KW": "dd/MM/yyyy",
      "en-PH": "M/d/yyyy",
      "es-CL": "dd-MM-yyyy",
      "ar-AE": "dd/MM/yyyy",
      "es-UY": "dd/MM/yyyy",
      "ar-BH": "dd/MM/yyyy",
      "es-PY": "dd/MM/yyyy",
      "ar-QA": "dd/MM/yyyy",
      "es-BO": "dd/MM/yyyy",
      "es-SV": "dd/MM/yyyy",
      "es-HN": "dd/MM/yyyy",
      "es-NI": "dd/MM/yyyy",
      "es-PR": "dd/MM/yyyy",
      "am-ET": "d/M/yyyy",
      "tzm-Latn-DZ": "dd-MM-yyyy",
      "iu-Latn-CA": "d/MM/yyyy",
      "sma-NO": "dd.MM.yyyy",
      "mn-Mong-CN": "yyyy/M/d",
      "gd-GB": "dd/MM/yyyy",
      "en-MY": "d/M/yyyy",
      "prs-AF": "dd/MM/yy",
      "bn-BD": "dd-MM-yy",
      "wo-SN": "dd/MM/yyyy",
      "rw-RW": "M/d/yyyy",
      "qut-GT": "dd/MM/yyyy",
      "sah-RU": "MM.dd.yyyy",
      "gsw-FR": "dd/MM/yyyy",
      "co-FR": "dd/MM/yyyy",
      "oc-FR": "dd/MM/yyyy",
      "mi-NZ": "dd/MM/yyyy",
      "ga-IE": "dd/MM/yyyy",
      "se-SE": "yyyy-MM-dd",
      "br-FR": "dd/MM/yyyy",
      "smn-FI": "d.M.yyyy",
      "moh-CA": "M/d/yyyy",
      "arn-CL": "dd-MM-yyyy",
      "ii-CN": "yyyy/M/d",
      "dsb-DE": "d. M. yyyy",
      "ig-NG": "d/M/yyyy",
      "kl-GL": "dd-MM-yyyy",
      "lb-LU": "dd/MM/yyyy",
      "ba-RU": "dd.MM.yy",
      "nso-ZA": "yyyy/MM/dd",
      "quz-BO": "dd/MM/yyyy",
      "yo-NG": "d/M/yyyy",
      "ha-Latn-NG": "d/M/yyyy",
      "fil-PH": "M/d/yyyy",
      "ps-AF": "dd/MM/yy",
      "fy-NL": "d-M-yyyy",
      "ne-NP": "M/d/yyyy",
      "se-NO": "dd.MM.yyyy",
      "iu-Cans-CA": "d/M/yyyy",
      "sr-Latn-RS": "d.M.yyyy",
      "si-LK": "yyyy-MM-dd",
      "sr-Cyrl-RS": "d.M.yyyy",
      "lo-LA": "dd/MM/yyyy",
      "km-KH": "yyyy-MM-dd",
      "cy-GB": "dd/MM/yyyy",
      "bo-CN": "yyyy/M/d",
      "sms-FI": "d.M.yyyy",
      "as-IN": "dd-MM-yyyy",
      "ml-IN": "dd-MM-yy",
      "en-IN": "dd-MM-yyyy",
      "or-IN": "dd-MM-yy",
      "bn-IN": "dd-MM-yy",
      "tk-TM": "dd.MM.yy",
      "bs-Latn-BA": "d.M.yyyy",
      "mt-MT": "dd/MM/yyyy",
      "sr-Cyrl-ME": "d.M.yyyy",
      "se-FI": "d.M.yyyy",
      "zu-ZA": "yyyy/MM/dd",
      "xh-ZA": "yyyy/MM/dd",
      "tn-ZA": "yyyy/MM/dd",
      "hsb-DE": "d. M. yyyy",
      "bs-Cyrl-BA": "d.M.yyyy",
      "tg-Cyrl-TJ": "dd.MM.yy",
      "sr-Latn-BA": "d.M.yyyy",
      "smj-NO": "dd.MM.yyyy",
      "rm-CH": "dd/MM/yyyy",
      "smj-SE": "yyyy-MM-dd",
      "quz-EC": "dd/MM/yyyy",
      "quz-PE": "dd/MM/yyyy",
      "hr-BA": "d.M.yyyy.",
      "sr-Latn-ME": "d.M.yyyy",
      "sma-SE": "yyyy-MM-dd",
      "en-SG": "d/M/yyyy",
      "ug-CN": "yyyy-M-d",
      "sr-Cyrl-BA": "d.M.yyyy",
      "es-US": "M/d/yyyy",
    };

    const dateFormat: string = formats[navigator.language] || "dd/MM/yyyy";
    return hours
      ? `${dateFormat.toUpperCase()} HH:mm`
      : dateFormat.toUpperCase();
  }

  public static localStorageSetItem(key: string, value: string): void {
    const apiPath: string = this.getApiPath().replace("/", ".");
    const contextPath: string = this.getContextPath().replace("/", "");
    const getProfileMode: string = this.getProfileMode();
    localStorage.setItem(`${apiPath}.${contextPath}.${getProfileMode}.${key}`, value);
  }

  public static localStorageGetItem(key: string): string {
    const apiPath: string = this.getApiPath().replace("/", ".");
    const contextPath: string = this.getContextPath().replace("/", "");
    const getProfileMode: string = this.getProfileMode();
    return localStorage.getItem(`${apiPath}.${contextPath}.${getProfileMode}.${key}`);
  }

  public static localStorageRemoveItem(key: string): void {
    const apiPath: string = this.getApiPath().replace("/", ".");
    const contextPath: string = this.getContextPath().replace("/", "");
    const getProfileMode: string = this.getProfileMode();
    localStorage.removeItem(`${apiPath}.${contextPath}.${getProfileMode}.${key}`);
  }

  public static dateFromTimestamp(timestamp: string, time: boolean): Date {
    let output: Date = new Date();
    if (timestamp) {
      const year: number = parseInt(timestamp.substr(0, 4));
      const month: number = parseInt(timestamp.substr(4, 2)) - 1;
      const day: number = parseInt(timestamp.substr(6, 2));
      const hours: number = parseInt(timestamp.substr(8, 2));
      const minutes: number = parseInt(timestamp.substr(10, 2));
      const seconds: number = parseInt(timestamp.substr(12, 2));

      output = time
        ? new Date(year, month, day, hours, minutes, seconds)
        : new Date(year, month, day);
    }
    return output;
  }

  public static dateStrigFromTimestamp(timestamp: string): string {
    let output: string = null;
    if (timestamp) {
      const year: string = timestamp.substr(0, 4);
      const month: string = timestamp.substr(4, 2);
      const day: string = timestamp.substr(6, 2);

      output = `${year}-${month}-${day}`;
    }
    return output;
  }

  public static addLayer(layerId: string, node: any): void {
    const layer: HTMLElement = document.createElement("div");
    layer.id = layerId;
    layer.classList.add("modules-layer");
    layer.setAttribute("style", "opacity: 0; z-index: 1;");
    node.prepend(layer);
  }

  public static removeLayer(layerId: string): void {
    const layer: any = document.getElementById(layerId);
    layer.remove();
  }

  public static async externalComponent2(componentName: string): Promise<any> {
    const url: string = this.getComponentRemotePath(
      `${componentName}.umd.min.js`
    );
    const defaultComponent: any = () => this.getCustomComponent(componentName);
    const name: any = url
      .split(`/`)
      .reverse()[0]
      .match(/^(.*?)\.umd/)[1];

    if (window[name]) return window[name];

    if (Utils.remoteComponentsActived()) {
      window[name] = <any>new Promise((resolve, reject) => {
        const script = document.createElement(`script`);
        script.async = true;
        script.addEventListener(`load`, (response: any) => {
          window[name] = defaultComponent();
          resolve(window[name]);
        });
        script.addEventListener(`error`, () => {
          window[name] = defaultComponent();
          resolve(window[name]);
        });
        script.src = url;
        document.head.appendChild(script);
      }).then((value: any) => {
        return value;
      });
    } else {
      window[name] = defaultComponent();
    }

    return window[name];
  }

  public static getCustomComponent(componentName: string): any {
    const customer: string = this.getApiPath().replace("/", "");
    const override: string = this.getOverridePath().replace("/", "");
    const components: any = this.customComponentsConfig[componentName];

    return (
      components[override] || components[customer] || components["default"]
    );
  }

  public static defaultApiErrorHandler(error: any, componentCaller?: any) {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          if (componentCaller) {
            Utils.localStorageRemoveItem(Utils.BEARER_ACCESS_TOKEN_KEY);
            if (Utils.localStorageGetItem(Utils.BEARER_REFRESH_TOKEN_KEY)) {
              componentCaller.$root.$emit("refreshToken");
            } else {
              componentCaller.$root.$emit("login");
            }
          }
          break;
        default:
          componentCaller.$router.push({
            name: "errors",
            params: { errorCode: error.response.status },
          });
      }
    } else {
      console.log(error);
      componentCaller.$router.push({
        name: "errors",
        params: { errorCode: "internal_error" },
      });
    }
  }

  public static remoteComponentsActived(): boolean {
    const remoteComponentActived: HTMLMetaElement = <HTMLMetaElement>(
      document.getElementsByName("_remote_components_actived")[0]
    );
    return remoteComponentActived
      ? remoteComponentActived.content.toLowerCase() === "true"
      : false;
  }

  public static getPropertyImageUrl(
    protertiesHolder: any,
    code: string,
    defaultUrl: string
  ): string {
    const property: DfProperty =
      protertiesHolder && protertiesHolder.properties
        ? protertiesHolder.properties.find(
            (property: DfProperty) =>
              property.type === "FILE" && property.code === code
          ) || null
        : null;

    return property && property.values.length > 0
      ? `${Utils.getContextPath(true)}/files/${property.values[0].uniqueId}/${
          property.values[0].basename
        }.${property.values[0].extension}`
      : defaultUrl;
  }

  public static getPropertyImagesUrl(
    protertiesHolder: any,
    code: string
  ): Array<string> {
    const property: DfProperty =
      protertiesHolder && protertiesHolder.properties
        ? protertiesHolder.properties.find(
            (property: DfProperty) =>
              property.type === "FILE" && property.code === code
          ) || null
        : null;

    return property && property.values.length > 0
      ? property.values.map((value: any) => {
          return `${Utils.getContextPath(true)}/files/${value.uniqueId}/${
            value.basename
          }.${value.extension}`;
        })
      : [];
  }

  public static getPropertyValue(
    protertiesHolder: any,
    code: string,
    type: string,
    defaultValue: any = null,
    useDefaultIfValuesIsEmpty = false
  ): any {
    const property: DfProperty =
      protertiesHolder && protertiesHolder.properties
        ? protertiesHolder.properties.find(
            (property: DfProperty) =>
              property.type === type && property.code === code
          ) || null
        : null;

    defaultValue = useDefaultIfValuesIsEmpty && !property ? null : defaultValue;

    return property && property.values.length > 0
      ? property.values[0]
      : defaultValue;
  }

  public static getPropertyValues(
    protertiesHolder: any,
    code: string,
    type: string
  ): any {
    const property: DfProperty =
      protertiesHolder && protertiesHolder.properties
        ? protertiesHolder.properties.find(
            (property: DfProperty) =>
              property.type === type && property.code === code
          ) || null
        : null;

    return property && property.values.length > 0 ? property.values : [];
  }

  public static storeGoogleMapsCoordinates(store: DfStore): string {
    let coordinates = "";
    if (store.gpsCoordinates) {
      coordinates = `${store.gpsCoordinates.latitude.toString()},${store.gpsCoordinates.longitude.toString()}`;
    } else {
      const provinceCode: string = store.province ? store.province.code : "";
      coordinates += store.address || "";
      coordinates += `, ${store.postalCode || ""}`;
      coordinates += `, ${store.city || ""}`;
      coordinates += `, ${provinceCode}`;
    }
    return coordinates;
  }

  public static setAnaliticsTraker(params: any) {
    //if (Utils.isAnaliticsTrakerEnabled()) {
    (<any>window).dataLayer = (<any>window).dataLayer || [];
    (<any>window).dataLayer.push(params);
    //}
  }

  public static goBack(caller: any) {
    if (caller.$store.getters.historyIndex > 1) {
      const historyIndex: number = caller.$store.getters.historyIndex - 2;
      const historyPage: any = caller.$store.getters.history[historyIndex];

      const checkPage: boolean =
        historyPage.name === "promotion" ||
        historyPage.name === "promotion-special";
      const hash: string =
        checkPage && caller.$route.hash ? caller.$route.hash : undefined;
      const offsetY: number = checkPage && caller.$route.hash ? 250 : undefined;
      const params: any = checkPage
        ? Object.assign({}, historyPage.params, { skipInitFilter: true })
        : historyPage.params;
      Store.dispatch("setHistoryIndex", historyIndex);
      caller.$router.push({
        name: historyPage.name,
        params: { ...params, offsetY },
        query: historyPage.query,
        hash: hash,
      });
    } else {
      caller.$router.push({
        name: "home-store",
        params: { storeAlias: caller.currentStore.alias },
      });
    }
  }

  public static isExternalLink(url: string) {
    return (url || "").startsWith("http");
  }

  public static isAthorizedRoute(route: any) {
    return (
      this.getAuthorizedRoutes().length === 0 ||
      this.getAuthorizedRoutes().includes(route.name)
    );
  }

  public static capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
}
export default Utils;
